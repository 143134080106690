// Custom button styles
.btn {
  text-transform: $btn-text-transform;
  letter-spacing: $btn-letter-spacing;
}

.btn-borderless {
  border: 0;
}

// More buttons
.btn-more::after {
  position: relative;
  top: 1px;
  content: "〉";
  display: inline-block;
  padding-left: 0.3em;
  color: inherit;
}

// Custom button groups
.btn-toolbar {
  .btn-toolbar-item {
    float: left;
  }

  > .btn-toolbar-item {
    margin-left: 5px;
  }
}

.btn-toolbar-divider {
  float: left;
  width: 1px;
  height: 34px;
  margin-left: 10px;
  margin-right: 5px;
  background-color: $btn-toolbar-divider-bg; // @eee
}

.btn-group-justified {
  &.btn-group-justified-spaced {
    width: calc(100% + 10px);
    margin-left: -5px;
    border-spacing: 5px;
  }
}

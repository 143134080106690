h5 a {
    color: #1997C6;

    // text-decoration: none;
}

h2.post-title a {
    color: #1997C6;
    text-decoration: none;
}

ul.links {
    margin: 0 0 25px;
    border-bottom: 0;
    padding: 5px 0;
}

.social-share li a {
    color: #1997C6;
}

.social-share li {
    display: inline-block; 
}

.social-share {
    list-style-type: none;
}

ul.links li a {
    color: #1997C6;

    // text-decoration: none;
}

ul.sidebar li a {
    color: #1997C6;
    text-decoration: none;
}

ul.links li {
    font-size: 12px;
    margin-right: 13px;
    font-weight: 300;
    display: inline-block; 
}

ul.links li ul {
    display: inline;
    padding: 0;
}

ul.sidebar {
    border-top: 2px solid #ededed;
    padding: 20px;
    margin-bottom: 50px;
    list-style: none;
}

ul.sidebar li {
    margin: 0 4px 10px 0;
    list-style: none;
}

.blog-btn.continue,
.blog-btn.back {
    color: #1997C6;
    text-decoration: none;
}

h2.post-title {
    text-transform: uppercase;

    // font-size: 22px;
    color: #1997C6;
}

.post-summary ul li a {
    text-decoration: none;
}

.blog-sidebar .rss-sitemap a {
    color: #1997C6;
}

.social-share-all {
    list-style-type: none;
    margin-bottom: 25px;
}

.social-share-all li.social-item {
    display: inline-block;
}

.social-share-all li.social-item a {
    color: #1997C6;
    text-decoration: none;
}

.footer-bottom .copy p {
    text-align: center;
}

.footer-bottom .copy p a {
    color: #1997C6;
}

.post-summary ul li {
    margin-bottom: 0;
}

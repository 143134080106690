// Custom alerts

.alert-dark {
  .close {
    text-shadow: 0 1px 0 #000;
  }

  .close:hover,
  .close:focus {
    color: #fff;
  }
}

.alert-full {
  border-radius: 0;
}

.datepicker {
  padding: 4px;
  border-radius: 4px;
  color: $gray-800;

  &-inline {
    width: 220px;
  }

  direction: ltr;

  &.datepicker-rtl {
    direction: rtl;

    table tr td span {
      float: right;
    }
  }

  &-dropdown {
    top: 0;
    left: 0;

    &::before {
      content: "";
      display: inline-block;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #ccc;
      border-top: 0;
      border-bottom-color: rgb(0 0 0 / 20%);
      position: absolute;
    }

    &::after {
      content: "";
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      border-top: 0;
      position: absolute;
    }
    &.datepicker-orient-left::before { left: 5px; }
    &.datepicker-orient-left::after { left: 6px; }
    &.datepicker-orient-right::before { right: 5px; }
    &.datepicker-orient-right::after { right: 6px; }
    &.datepicker-orient-top::before { top: -6px; }
    &.datepicker-orient-top::after { top: -5px; }

    &.datepicker-orient-bottom::before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid #999;
    }

    &.datepicker-orient-bottom::after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid #fff;
    }
  }

  > div {
    display: none;
  }

  &.days .datepicker-days,
  &.months .datepicker-months,
  &.years .datepicker-years {
    display: block;
  }

  table {
    margin: 5px 10px;
    -webkit-touch-callout: none;
    user-select: none;

    > tbody,
    > thead {
      > tr {
        > td,
        > th {
          text-align: center;
          width: 30px;
          height: 30px;
          border-radius: 4px;
          border: none;
        }
      }
    }
  }

  // Inline display inside a table presents some problems with
  // border and background colors.
  .table-striped & table tr {
    td,
    th {
      background-color: transparent;
    }
  }

  // table tr td {
  //   &.day:hover,
  //   &.day.focused {
  //     background: $gray-100;
  //     cursor: pointer;
  //   }

  //   &.old,
  //   &.new {
  //     color: $btn-link-disabled-color;
  //   }

  //   &.disabled,
  //   &.disabled:hover {
  //     background: none;
  //     color: $btn-link-disabled-color;
  //     cursor: default;
  //   }

  //   &.today,
  //   &.today:hover,
  //   &.today.disabled,
  //   &.today.disabled:hover {
  //     @include button-variant(#000, lighten(orange, 30%), darken(lighten(orange, 30%), 20%));
  //   }

  //   &.today:hover:hover { // Thank bootstrap 2.0 for this selector...
  //     // TODO: Bump min BS to 2.1, use @textColor in buttonBackground above
  //     color: #000;
  //   }

  //   &.today.active:hover {
  //     color: #fff;
  //   }

  //   &.range,
  //   &.range:hover,
  //   &.range.disabled,
  //   &.range.disabled:hover {
  //     background: $gray-100;
  //     border-radius: 0;
  //   }

  //   &.range.today,
  //   &.range.today:hover,
  //   &.range.today.disabled,
  //   &.range.today.disabled:hover {
  //     $today-bg: mix(orange, $gray-100, 50%);

  //     @include button-variant(#000, $today-bg, darken($today-bg, 20%));

  //     border-radius: 0;
  //   }

  //   &.selected,
  //   &.selected:hover,
  //   &.selected.disabled,
  //   &.selected.disabled:hover {
  //     @include button-variant(#fff, $gray-300, $gray-500);

  //     text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
  //   }

  //   &.active,
  //   &.active:hover,
  //   &.active.disabled,
  //   &.active.disabled:hover {
  //     @include button-variant(theme-color($key: "primary"), theme-color($key: "primary"));

  //     text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
  //   }

  //   span {
  //     display: block;
  //     width: 23%;
  //     height: 54px;
  //     line-height: 54px;
  //     float: left;
  //     margin: 1%;
  //     cursor: pointer;
  //     border-radius: 4px;

  //     &:hover {
  //       background: $gray-100;
  //     }

  //     &.disabled,
  //     &.disabled:hover {
  //       background: none;
  //       color: $btn-link-disabled-color;
  //       cursor: default;
  //     }

  //     &.active,
  //     &.active:hover,
  //     &.active.disabled,
  //     &.active.disabled:hover {
  //       @include button-variant(theme-color($key: "primary"), theme-color($key: "primary"));

  //       text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
  //     }

  //     &.old,
  //     &.new {
  //       color: $btn-link-disabled-color;
  //     }
  //   }
  // }

  .datepicker-switch {
    width: 145px;
  }

  // thead tr:first-child th,
  // tfoot tr th {
  //   cursor: pointer;

  //   &:hover {
  //     background: $gray-100;
  //   }
  // }

  // Basic styling for calendar-week cells
  .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }

  thead tr:first-child .cw {
    cursor: default;
    background-color: transparent;
  }
}

.input-group.date .input-group-addon {
  cursor: pointer;
}

.input-daterange {
  width: 100%;

  input {
    text-align: center;
  }

  input:first-child {
    border-radius: 3px 0 0 3px;
  }

  input:last-child {
    border-radius: 0 3px 3px 0;
  }

  // .input-group-addon {
  //   width: auto;
  //   min-width: 16px;
  //   padding: 4px 5px;
  //   font-weight: normal;
  //   line-height: $line-height-base;
  //   text-align: center;
  //   text-shadow: 0 1px 0 #fff;
  //   vertical-align: middle;
  //   background-color: $input-group-addon-bg;
  //   border: solid $input-group-addon-border-color;
  //   border-width: 1px 0;
  //   margin-left: -5px;
  //   margin-right: -5px;
  // }
}

@use "sass:color";

.statcard-number {
  margin-top: 0;
  margin-bottom: 0;

  .badge {
    font-size: 45%;
    vertical-align: middle;
  }
}

.statcard-desc {
  font-size: 85%;
  letter-spacing: 0.15em;
  color: color.adjust($gray-700, $lightness: 20%);
  text-transform: uppercase;
}

.delta-indicator {
  display: inline-block;
  padding: 0.4em;
  font-size: 12px;
  vertical-align: middle;

  &::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

.delta-positive {
  color: theme-color("success");

  &::after {
    border-top: 0;
    border-bottom: 4px solid;
  }
}

.delta-negative {
  color: theme-color("danger");

  &::after {
    border-bottom: 0;
    border-top: 4px solid;
  }
}

.statcard-primary,
.statcard-success,
.statcard-info,
.statcard-warning,
.statcard-danger {
  color: #fff;
  border-radius: 3px;

  .delta-negative,
  .delta-positive,
  .statcard-number {
    font-weight: normal;
    color: inherit;
  }

  .statcard-desc {
    font-weight: normal;
    color: rgb(255 255 255 / 65%);
  }

  .statcard-hr {
    margin-left: -1rem;
    margin-right: -1rem;
    border-top-color: rgb(255 255 255 / 20%);
  }
}

.statcard-primary { background-color: #9f86ff; }
.statcard-success { background-color: #1bc98e; }
.statcard-info { background-color: #9f86ff; }
.statcard-warning { background-color: #e4d836; }
.statcard-danger { background-color: #e64759; }


.carousel-caption {
  z-index: 1;
}

.carousel-item::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, black, transparent);
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}

.app-high-praise::after {
  position: absolute;
  content: "";
  background: linear-gradient(to left, black, transparent);
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}

.high-praise {
  z-index: 1 !important;
}

// Transparent navbar
.navbar-transparent {
  background-color: $navbar-transparent-bg;
  border-color: $navbar-transparent-border;

  .navbar-brand {
    color: $navbar-transparent-brand-color;

    &:hover,
    &:focus {
      background-color: $navbar-transparent-brand-hover-bg;
    }
  }

  .navbar-text {
    color: $navbar-transparent-color;
  }

  .navbar-nav {
    > li > a {
      color: $navbar-transparent-link-color;

      &:hover,
      &:focus {
        background-color: $navbar-transparent-link-hover-bg;
      }
    }

    // > .active > a {
    //   &,
    //   &:hover,
    //   &:focus {
    //     color: $navbar-transparent-link-active-color;
    //     background-color: $navbar-transparent-link-active-bg;
    //   }
    // }

    // > .disabled > a {
    //   &,
    //   &:hover,
    //   &:focus {
    //     color: $navbar-transparent-link-disabled-color;
    //     background-color: $navbar-transparent-link-disabled-bg;
    //   }
    // }
  }

  .navbar-toggler {
    background-repeat: no-repeat;
    background-position: center;
    border-color: $navbar-transparent-toggler-border-color;
    background-image: $navbar-transparent-toggler-bg;

    &:hover,
    &:focus {
      background-color: $navbar-transparent-toggler-hover-bg;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: $navbar-transparent-border;
  }

  // Dropdown menu items
  // .navbar-nav {
  //   // Remove background color from open dropdown
  //   > .open > a {
  //     &,
  //     &:hover,
  //     &:focus {
  //       background-color: $navbar-transparent-link-active-bg;
  //       color: $navbar-transparent-link-active-color;
  //     }
  //   }

  //   @media (max-width: map-get($grid-breakpoints, sm)) {
  //     // Dropdowns get custom display when collapsed
  //     .open .dropdown-menu {
  //       > li > a {
  //         color: $navbar-transparent-link-color;

  //         &:hover,
  //         &:focus {
  //           color: $navbar-transparent-link-hover-color;
  //           background-color: $navbar-transparent-link-hover-bg;
  //         }
  //       }

  //       > .active > a {
  //         &,
  //         &:hover,
  //         &:focus {
  //           color: $navbar-transparent-link-active-color;
  //           background-color: $navbar-transparent-link-active-bg;
  //         }
  //       }

  //       > .disabled > a {
  //         &,
  //         &:hover,
  //         &:focus {
  //           color: $navbar-transparent-link-disabled-color;
  //           background-color: $navbar-transparent-link-disabled-bg;
  //         }
  //       }
  //     }
  //   }
  // }

  // Links in navbars
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .navbar-link {
    color: $navbar-transparent-link-color;
  }

  .btn-link {
    color: $navbar-transparent-link-color;
  }
}

/* stylelint-disable-next-line selector-id-pattern */
input#id_name {
    background: transparent !important;
    border: 1px solid lightgray !important;
}

/* stylelint-disable-next-line selector-id-pattern */
textarea#id_how_can_we_help {
    background: transparent !important;
    border: 1px solid lightgray !important;
}

/* stylelint-disable-next-line selector-id-pattern */
input#id_email {
    background: transparent !important;
    border: 1px solid lightgray !important;
}

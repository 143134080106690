// Position Utilities
// -------------------------
.pos-r { position: relative !important; }
.pos-a { position: absolute !important; }
.pos-f { position: fixed !important; }

// Constrain utilities
// -------------------------
.w-1 { width: 25% !important; }
.w-2 { width: 50% !important; }
.w-3 { width: 75% !important; }
.w-full { width: 100% !important; }

@import "~bootstrap/scss/bootstrap.scss";
@import "theme-marketing/application-startup.scss";
@import "theme-marketing/toolkit-startup.scss";
@import "theme-dashboard/toolkit-light.scss";
@import "theme-dashboard/toolkit-inverse.scss";
@import "theme-aclarknet/index.scss";
@import "puput.scss";

#theme-toggler-authenticated:hover {
    cursor: pointer; /* Change cursor to pointer on hover */
    color: #007bff; /* Change color on hover */
}

#theme-toggler-anonymous:hover {
    cursor: pointer; /* Change cursor to pointer on hover */
    color: #007bff; /* Change color on hover */
}

.accordion-button {
    background: transparent !important;    
}


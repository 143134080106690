// Custom button radius sizes

.btn-pill {
  padding-left: 1.25em;
  padding-right: 1.25em;
  border-radius: 1000em;
}

.btn-square {
  border-radius: 0;
}

// Icon nav

.iconav {
  margin-top: -20px; // offset the min body padding
  margin-bottom: 30px;

  @include media-breakpoint-up(md) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: $iconav-width;
    margin-top: 0;
    margin-bottom: 0;
    background-color: $iconav-bg;
    overflow-y: auto;
    transform: translate3d(0, 0, 0); // Make the sidebar scrollable at all times in Safari
  }

  .tooltip {
    white-space: nowrap;
  }
}

// Account for the width of the iconav
.with-iconav > .container,
.with-iconav > .container-fluid {
  @include media-breakpoint-up(md) {
    padding-left: 85px;
  }
}

.iconav-brand {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 1;
  color: $iconav-brand-color;
  text-align: center;
  border-bottom: 0;
  background-color: $iconav-brand-bg;

  @include media-breakpoint-up(md) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &:hover {
    color: $iconav-brand-hover-color;
    text-decoration: none;
  }
}

.iconav-brand-icon {
  font-size: 30px;
}

.iconav-nav {
  flex-wrap: nowrap;

  // For unread counts and the like
  .label {
    position: absolute;
    top: 8px;
    right: 12px;
    z-index: 5;
    padding: 0.2em 0.4em;
    font-size: 11px;
    line-height: 1;
    border-radius: 10px;
    border: 2px solid $iconav-bg;
  }

  // Nav items
  > .nav-item {
    float: none;
    display: inline-block;

    @include media-breakpoint-up(md) {
      display: block;
    }

    > .nav-link {
      position: relative;
      padding: 10px 12px;
      font-size: 18px;
      color: $iconav-link-color;
      text-align: center;
      border-radius: 0;

      @include media-breakpoint-up(md) {
        padding: 15px;
      }
    }

    + .nav-item {
      margin-left: -3px;

      > .nav-link {
        border-left: 1px solid $iconav-link-border;

        &.active {
          &,
          &:focus,
          &:hover,
          &:active {
            color: $iconav-link-active-color;
            background-color: $iconav-link-active-bg;

            .label {
              border-color: $iconav-link-active-bg;
            }
          }
        }
      }

      @include media-breakpoint-up(md) {
        margin-left: 0;

        > .nav-link {
          border-top: 1px solid $iconav-link-border;
          border-right: 0;
        }
      }
    }

    > .nav-link > img {
      width: 26px;
      margin: 0 auto;
    }
  }
}

// Slider nav
// Move from a horizontal slider nav like iOS's tab bar to a stacked nav easily.

// Show text labels for xs viewports (uses inline utility classes for toggling visibility)
.iconav-nav-label {
  display: block;
  font-size: 11px;
}

// Make it slide
@include media-breakpoint-down(sm) {
  .iconav-slider {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
}
